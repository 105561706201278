<template>
  <div id="app">
    <div
      v-if="$root.ready && overflow"
      class="ready-overflow"
      @click="openWebsite()"
    >
      <button
        class="btn btn-outline-primary text-uppercase"
        @click="openWebsite()"
      >
        Buka Undangan
      </button>
    </div>

    <AGWAudio
      :file="music.file"
      :loop="music.loop"
      :autoplay="music.autoplay"
      ref="audio"
    />
    <AGWHero
      overlay
      :images="backgrounds"
      ref="hero"
      parallax
      target="#intro"
    />

    <div
      class="agw-content bg-white"
      :style="{ 'margin-top': `${$root.hero.height}px` }"
    >
      <AGWIntro id="intro" />

      <AGWPawiwahan id="pawiwahan" class="bg-blue-light py-5" />
      <AGWMepandes id="mepandes" class="pad-y-60" :mepandes="mepandes" />

      <AGWCounter
        id="counter"
        class="pad-y-60"
        :background="background"
        :date="time.start"
      />
      <AGWGallery id="gallery" class="py-5" />
      <AGWMap
        :height="450"
        url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3944.3716113881014!2d115.21985561478388!3d-8.656162393779988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x103761059e283fb0!2sGKII%20Imanuel%20Denpasar!5e0!3m2!1sen!2sid!4v1613139046707!5m2!1sen!2sid"
        directionUrl="https://www.google.com/maps/dir//GKII+Imanuel+Denpasar,+Jl.+Melati+No.3,+Dangin+Puri,+East+Denpasar,+Denpasar+City,+Bali+80232/@-8.6561624,115.2198556,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x2dd2409ce56a06c1:0x103761059e283fb0!2m2!1d115.2220443!2d-8.6561624!3e0"
        :location="location"
        id="map"
      />
    </div>

    <div class="agw-content" style="background: transparent">
      <attendance id="attendance" class="pad-y-60"></attendance>
    </div>

    <section class="footer bg-white pad-y-20" ref="footer">
      <div class="font-weight-lighter">
        &copy; {{ $moment().format("YYYY") }}. Made by
        <a href="https://ceremonyku.com">ceremonyku</a>.
      </div>
    </section>

    <transition name="slide-fade">
      <guest-box :floating="true" v-if="$root.scroll.Y > $root.hero.height" />
    </transition>

    <div class="snackbar" v-if="sw.updateExists">
      New version available!
      <button @click="refreshApp" class="btn">REFRESH</button>
    </div>
  </div>
</template>

<script>
const ImageHero1 =
  "https://ik.imagekit.io/aryraditya/ceremonyku/henry-wida/SKF_6379_NY6pDkOrD.jpg?tr=w-800";
const ImageHero2 =
  "https://ik.imagekit.io/aryraditya/ceremonyku/henry-wida/SKF_6468_hq0XUkxcN.jpg?tr=w-800";
const ImageHero3 =
  "https://ik.imagekit.io/aryraditya/ceremonyku/henry-wida/SKF_6426_qjMZEIGB-.jpg?tr=w-800";

import Audio from "@/assets/audio/backsound.mp3";

import AGWHero from "@/components/Hero/HeroTwo";
import AGWIntro from "@/components/Intro";
import AGWPawiwahan from "@/components/Pawiwahan";
import AGWMepandes from "@/components/Mepandes";
import AGWCounter from "@/components/Counter";
import AGWGallery from "@/components/Gallery/GalleryOne";
import AGWMap from "@/components/Maps";
import AGWAudio from "@/components/Audio";
import GuestBox from "@/components/GuestBox";
import Attendance from "@/components/Attendance";

import { setInterval } from "timers";

export default {
  name: "app",
  components: {
    AGWHero,
    AGWIntro,
    AGWPawiwahan,
    AGWMepandes,
    AGWCounter,
    AGWGallery,
    AGWMap,
    AGWAudio,
    GuestBox,
    Attendance,
  },
  data() {
    return {
      title: "Pawiwahan",
      backgrounds: [ImageHero1, ImageHero2, ImageHero3],
      bgActive: 0,
      dividerHeight: 0,
      overflow: true,
      location: "GKII Imanuel Denpasar <br />Jl. Melati No 3. Dangin Puri, Denpasar Timur, Denpasar - Bali",
      // date: "2020-04-05",
      time: {
        start: this.$moment("2021-02-22 10:00"),
        end: "Selesai",
      },
      wedding: {
        man: {
          name: "Putu Henry Nugraha",
          surname: "Henry",
          parents: "I Made Simon & Ni Nyoman Sriani",
          description: "Putra pertama dari 3 bersaudara",
          address: "Jl. Singosari, GG Anggrek 6, Peguyangan",
          photo:
            "https://ik.imagekit.io/aryraditya/ceremonyku/henry-wida/SKF_6510_bu4Bvef6Rk.jpg?tr=w-400",
        },
        woman: {
          name: "Made Ayu Wida Cesartika",
          surname: "Wida",
          parents: "Nyoman Oka & Alm. Ketut Orhibawanti Kemenuhi",
          address: "Jl. Yudistira I No 2 M. Sari, Seririt, Singaraja",
          description: "Putri kedua dari 3 bersaudara",
          photo:
            "https://ik.imagekit.io/aryraditya/ceremonyku/henry-wida/SKF_6394_xe2pV5OFM.jpg?tr=w-400",
        },
      },
      mepandes: [],
      gallery: [],
      music: {
        file: Audio,
        autoplay: true,
        loop: true,
      },
      sw: {
        refreshing: false,
        registration: null,
        updateExists: false,
      },
    };
  },
  computed: {
    background() {
      return this.backgrounds[this.bgActive];
    },
  },
  mounted() {
    this.dividerHeight = `calc(100vh - ${this.$refs.footer.offsetHeight}px)`;
    setInterval(() => {
      let active = this.bgActive + 1;
      if (active >= this.backgrounds.length) {
        active = 0;
      }

      this.bgActive = active;
    }, 5000);
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.sw.refreshing) return;
        this.sw.refreshing = true;
        window.location.reload();
      });
    }
  },
  methods: {
    showRefreshUI(e) {
      this.sw.registration = e.detail;
      this.sw.updateExists = true;
    },
    refreshApp() {
      this.sw.updateExists = false;
      if (!this.sw.registration || !this.sw.registration.waiting) {
        return;
      }
      this.sw.registration.waiting.postMessage("skipWaiting");
    },
    openWebsite() {
      this.overflow = false;
      document.querySelector("#loading").classList.remove("active");
      if (this.$refs.audio) this.$refs.audio.play();
    }
  },
};
</script>
